import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Progress,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card, CardTitle, CardBody,
  Spinner,

  
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Widget from "../../components/Widget/Widget.js";
import ApexActivityChart from "./components/ActivityChart.js";
import env from "react-dotenv";
import axios from "axios";
import Notification from "../../components/Notification/Notification.js";
import { Link } from "react-router-dom";

import meal1 from "../../assets/dashboard/meal-1.svg";
import meal2 from "../../assets/dashboard/meal-2.svg";
import meal3 from "../../assets/dashboard/meal-3.svg";
import upgradeImage from "../../assets/dashboard/upgradeImage.svg";
import heartRed from "../../assets/dashboard/heartRed.svg";
import heartTeal from "../../assets/dashboard/heartTeal.svg";
import heartViolet from "../../assets/dashboard/heartViolet.svg";
import heartYellow from "../../assets/dashboard/heartYellow.svg";
import gymIcon from "../../assets/dashboard/gymIcon.svg";
import therapyIcon from "../../assets/dashboard/therapyIcon.svg";
import user from "../../assets/user.svg";
import statsPie from "../../assets/dashboard/statsPie.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import * as Icons from "@material-ui/icons";
import ellieSmithImg from "../../assets/tables/ellieSmithImg.png";
import floydMilesImg from "../../assets/tables/floydMilesImg.png";
import rosaFloresImg from "../../assets/tables/rosaFloresImg.png";
import janeCooperImg from "../../assets/tables/janeCooper.png";
import bagIcon from "../../assets/tables/bagIcon.svg";
import folderIcon from "../../assets/tables/folderIcon.svg";
import joystickIcon from "../../assets/tables/joystickIcon.svg";
import basketIcon from "../../assets/tables/basketIcon.svg";
import store from "../../store/index.js";
import AddShopModal from "../../components/AddShopModal";
import Moment from 'react-moment';

import moment from "moment";

import Model from "../Store/components/Modal";

// import s from "./Dashboard.module.scss";
import s from "./Tables.module.scss";
var app_mode = env.MODE ? env.MODE: 'development'
var default_url = app_mode == 'production'? "https://api.mistershoppie.com/" : "https://api.keralashoppie.com/";
console.log('env.MODE',env.MODE, default_url)

const api_url =env.API_URL?env.API_URL: default_url;


// env.API_URL? env.API_URL : 'http://localhost:8000/api/v1/'
const options = {
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
};

class Report extends React.Component {
  constructor(props) {
    super(props);

    this.toggleOne = this.toggleOne.bind(this);
    this.toggleTwo = this.toggleTwo.bind(this);
    this.toggleThree = this.toggleThree.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);

    this.state = {
      dropdownOpenOne: false,
      dropdownOpenTwo: false,
      dropdownOpenThree: false,
      tableDropdownOpen: false,
      checkboxes: [false, true],
      secondTableCurrentPage: 1,
      orders: [],
      secondTable: [],
      formFlag: false,
      modal: false,
      backdrop: true,
      keyboard: true,
      show: false,
      language: store.getState().user.language,
      current_page: 1,
      editShow: false,
      status: null,
      id: null,
      order_deatil : [],
      timer :null,
      loadFlag : false,
      total_amount : 0,
      store_details : {},
      from_date : moment(new Date()).format('Y-M-D'),
      to_date : moment(new Date()).format('Y-M-D'),
      total_delivery_amount :0,
      total_order_amount : 0,
      
    };
    store.subscribe(this.handleStoreUpdate);
  }
  handleStoreUpdate = () => {
    this.setState({ language: store.getState().user.language });
  };
  meals = [meal1, meal2, meal3];

  toggleOne() {
    this.setState({
      dropdownOpenOne: !this.state.dropdownOpenOne,
    });
  }
  tableMenuOpen = () => {
    this.setState({
      tableDropdownOpen: !this.state.tableDropdownOpen,
    });
  };

  toggleTwo() {
    this.setState({
      dropdownOpenTwo: !this.state.dropdownOpenTwo,
    });
  }

  toggleThree() {
    this.setState({
      dropdownOpenThree: !this.state.dropdownOpenThree,
    });
  }

  changeCheck(event, checkbox, id) {
    this.state[checkbox][id] = event.target.checked;

    if (!event.target.checked) {
      this.state[checkbox][id] = false;
    }
    this.setState({
      [checkbox]: this.state[checkbox],
    });
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleEditShow = () => {
    this.setState({ editShow: !this.state.editShow });
  };
  startDateHandler = (event) =>{
    this.setState({ from_date: event.target.value });
    this.getReports(event.target.value,this.state.to_date);
  }
  endDateHandler = (event) =>{
    this.setState({ to_date: event.target.value });
    this.getReports(this.state.from_date,event.target.value);

  }
  getReports = (from_date, to_date) => {
    this.setState({loadFlag:true})

    axios
      .get(
        api_url +
          "api/v1/dashboard/reports/orders?from_date="+from_date+"&to_date="+to_date+"&status=delivered",
        store.getState().user.requestHeader
      )
      .then((result) => {
        this.setState({loadFlag:false})

        // console.log("from api client", result.data);
        this.setState({ orders: result.data.data, total_order_amount :  result.data.total_amount,total_delivery_amount :  result.data.total_delivery_amount});
      })
      .catch((e) => {
        this.setState({loadFlag:false})

        console.log("error");
      });
  };
  
  
  storeName = (name) => {
    try {
      return name[this.state.language];
    } catch (error) {
      console.log(name, error);
      return name;
    }
  };
  
  paginate = (e, type) => {
    e.preventDefault();
    var page = this.state.current_page;

    if (type == "minus") {
      page = page == 1 ? 1 : page - 1;
    } else {
      page = page + 1;
    }
    this.setState({ current_page: page });
    this.getOrders(page);
  };
  componentDidMount() {
    // this.timer = setInterval(()=> this.getOrders(1), 100000);
    this.getReports(this.state.from_date,this.state.to_date);
  }
  componentWillUnmount() {
    this.timer = null; 
  }

  updateHandler(id) {
    axios
    .get(api_url + "api/v1/store_orders/"+id, store.getState().user.requestHeader)
    .then((result) => {
      this.setState({
        status: this.state.orders.find((order) => order.order_id === id).status,
      });
      this.setState({
        order_deatil: result.data.data, 
        total_amount:result.data.total,
        store_details : result.data.store
      });
      
      this.setState({ id });
      this.handleEditShow();
    })
    .catch((e) => {
      toast(<Notification type={"error"} message="error saved" />, options);
      console.log("error", e);
    });

    
  }

  onStatusChange(event) {
    event.preventDefault();
    this.setState({ status: event.target.value });
  }

  
  async onUpdateSubmit(event) {
    event.preventDefault();

    try {
      axios.put(
        api_url + `api/v1/dashboard-orders/${this.state.id}`,
        { status: this.state.status },
        store.getState().user.requestHeader
      ).then(response => {
        toast(
          <Notification type={"success"} message="successfully saved" />,
          options
        );
          this.getStores(1);
          this.handleEditShow();


      }).catch(e => {
        console.log(e);

      })
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <Model
          show={this.state.editShow}
          modalHandler={this.handleEditShow}
          title="Update Order"
          submitHandler={this.onUpdateSubmit}
        >
          <Row>
            <Col md="6">
              <FormGroup check>
                <legend className="col-form-label col-sm-2">Status</legend>
                <Col md="4">
                  <Label check>accepted : </Label>
                  <Input
                    name="status"
                    className="ml-2"
                    value="accepted"
                    type="radio"
                    checked={this.state.status === "accepted"}
                    onChange={this.onStatusChange}
                  />
                </Col>
                <Col md="4">
                  <Label check>pending : </Label>
                  <Input
                    name="status"
                    className="ml-2"
                    value="pending"
                    type="radio"
                    checked={this.state.status === "pending"}
                    onChange={this.onStatusChange}
                  />
                </Col>
                <Col md="4">
                  <Label check>delivered : </Label>
                  <Input
                    name="status"
                    className="ml-2"
                    value="delivered"
                    type="radio"
                    checked={this.state.status === "delivered"}
                    onChange={this.onStatusChange}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md="4">
              <h4> { this.storeName(this.state.store_details.store_name)}</h4>
              <p>{this.state.store_details.phone_number ?this.state.store_details.phone_number:'' }</p>
              <p>{this.state.store_details.city ? this.state.store_details.city:'' }</p>
              <p>{this.state.store_details.address ?this.state.store_details.address:'' }</p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
            <Card>
            <CardBody>
            <CardTitle tag="h5">Order details</CardTitle>

            <Table
                      className="table-striped table-borderless "
                      responsive
                    >
                      <thead>
                        <tr>
                         
                          <th className={s.nameCol}>NAME</th>
                          <th>Store name</th>
                          <th>price</th>
                          <th>offer price</th>
                          <th>Qty</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.order_deatil.length > 0 ? this.state.order_deatil.map((item) => (

                          item.type != 'custom' ? 
                          <tr key={uuidv4()}>
                            
                            <td className="d-flex align-items-center">
                              <img
                                className={s.image}
                                src={item.product_image}
                                alt="User"
                              />
                              
                                {this.storeName(item.product_name)}
                            </td>
                            {/* <td>{item.name}</td> */}
                            <td>{this.storeName(item.store_name)}</td>
                            <td>{item.price}</td>
                            <td>{item.offer_price}</td>
                            <td>{item.quantity}</td>
                            <td>{item.sub_total}</td>
                            
                            
                          </tr> : <tr> 
                            <td>{item.description} </td>
                            <td>this is custom order </td>
                            </tr>
                        )) : 
                        <tr> 
                            <td>no orders found </td>
                            </tr>}
                            {this.state.order_deatil.length > 0 && 
                              <tr> 
                                <td colSpan={5}><h5>Total amount for this order</h5>  </td>
                                <td ><h3>₹ {this.state.total_amount?this.state.total_amount:''} </h3>  </td>
                              </tr>
                            }
                      </tbody>
                    </Table>
                    </CardBody>
                    </Card>
            </Col>
          </Row>
        </Model>
        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                <Widget>
                  <div className={s.tableTitle}>
                  <div className="headline-2">Delivery Report</div>

                    <div className="bg-widget d-flex ">
                      <FormGroup>
                        <Label>Start date</Label>
                        <Input type="date" onChange={this.startDateHandler} valid={this.state.from_date}/>

                      </FormGroup>
                      <FormGroup className="ml-3">
                        <Label>End date</Label>
                        <Input type="date" onChange={this.endDateHandler} valid={this.state.to_date}/>

                      </FormGroup>
                    </div>

                    {this.state.loadFlag && (
                    <div className="bg-widget d-flex justify-content-center">
                      <Spinner type="grow" color="dark" />
                      <Spinner type="grow" color="warning" />
                      <Spinner type="grow" color="dark" />
                    </div>
                  ) }

                    {/* <Button onClick={this.toggle} color="warning">Add New Order</Button> */}
                  </div>
                  <div className="widget-table-overflow">
                    <Table
                      className="table-striped table-borderless "
                      responsive
                    >
                      <thead>
                      <tr>
                          <th className={s.nameCol}></th>
                          <th></th>
                          <th className="text-success">Total: {this.state.total_order_amount} ₹</th>
                          <th className="text-success">Total:{this.state.total_delivery_amount} ₹</th>
                          <th></th>
                        </tr>
                        <tr>
                          <th className={s.nameCol}>OrderID</th>
                          <th>Type</th>
                          <th>Order Amount</th>
                          <th>Delivery charge</th>
                          <th>Need by date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.orders.map((item) => (
                          <tr key={uuidv4()}>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">#00{item.order_id}</span>
                            </td>
                            {/* <td>{item.name}</td> */}
                            <td>
                              {item.payment_mode}
                            </td>
                            <td>
                              {item.amount}
                            </td>
                            <td>
                            {item.delivery_charge}

                            </td>
                            <td>
                              <Moment format="D MMM YYYY - HH:mm A">
                                  {item.date}
                              </Moment>
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination className="pagination-with-border">
                      <PaginationItem>
                        <PaginationLink
                          previous
                          href="#top"
                          onClick={(e) => this.paginate(e, "minus")}
                        />
                      </PaginationItem>

                      <PaginationItem>
                        <PaginationLink
                          next
                          href="#top"
                          onClick={(e) => this.paginate(e, "plus")}
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
        </Row>
        <AddShopModal
          toggle={this.toggle}
          modal={this.state.modal}
          saveShop={this.saveShop}
          formFlag={this.state.formFlag}
        />
      </div>
    );
  }
}

export default Report;
