import React from 'react';

class SofiaLogo extends React.Component {

  render() {
    return (
      <img src="/assets/img/logo.png" width="35" height="40"/>
    );
  }
}

export default SofiaLogo;
