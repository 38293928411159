import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Progress,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import Widget from "../../components/Widget/Widget.js";
import ApexActivityChart from "./components/ActivityChart.js";
import env from "react-dotenv";
import axios from "axios";
import { toast } from "react-toastify";
import store from "../../store/index.js";
import meal1 from "../../assets/dashboard/meal-1.svg";
import meal2 from "../../assets/dashboard/meal-2.svg";
import meal3 from "../../assets/dashboard/meal-3.svg";
import upgradeImage from "../../assets/dashboard/upgradeImage.svg";
import heartRed from "../../assets/dashboard/heartRed.svg";
import heartTeal from "../../assets/dashboard/heartTeal.svg";
import heartViolet from "../../assets/dashboard/heartViolet.svg";
import heartYellow from "../../assets/dashboard/heartYellow.svg";
import gymIcon from "../../assets/dashboard/gymIcon.svg";
import therapyIcon from "../../assets/dashboard/therapyIcon.svg";
import user from "../../assets/user.svg";
import statsPie from "../../assets/dashboard/statsPie.svg";
import * as Icons from "@material-ui/icons";
import s from "./Dashboard.module.scss";
import Moment from 'react-moment';
import Notification from "../../components/Notification/Notification";

var app_mode = env.MODE ? env.MODE: 'development';

var default_url = app_mode == 'production'? "https://api.mistershoppie.com/" : "https://api.keralashoppie.com/";
console.log('env.MODE',env.MODE, default_url)

const options = {
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
};
const api_url =env.API_URL?env.API_URL: default_url;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.toggleOne = this.toggleOne.bind(this);
    this.toggleTwo = this.toggleTwo.bind(this);
    this.toggleThree = this.toggleThree.bind(this);

    this.state = {
      dropdownOpenOne: false,
      dropdownOpenTwo: false,
      dropdownOpenThree: false,
      checkboxes: [false, true],
      orders : [],
      profile : {},
      profileCounts:{},
      editFlag : false,

    };
  }

  meals = [meal1, meal2, meal3];

  toggleOne() {
    this.setState({
      dropdownOpenOne: !this.state.dropdownOpenOne,
    });
  }
  toggleEdit = () => {
    this.setState({editFlag:!this.state.editFlag});
  }

  handleChange = (event) => {
		const value = event.target.value;
        this.setState(currentState => ({profile: {...currentState.profile, [event.target.name]: value}}));
	}

  toggleTwo() {
    this.setState({
      dropdownOpenTwo: !this.state.dropdownOpenTwo,
    });
  }

  toggleThree() {
    this.setState({
      dropdownOpenThree: !this.state.dropdownOpenThree,
    });
  }

  changeCheck(event, checkbox, id) {
    this.state[checkbox][id] = event.target.checked;

    if (!event.target.checked) {
      this.state[checkbox][id] = false;
    }
    this.setState({
      [checkbox]: this.state[checkbox],
    });
  }
  componentDidMount(){
    this.getProfile();

  }
  
  getProfile = () => {
    axios
      .get(
        api_url +
          "api/v1/dashboard/profile" ,
        store.getState().user.requestHeader
      )
      .then((result) => {
        // console.log("from api client", result.data);
        this.setState({ profile: result.data.data });
      })
      .catch((e) => {
        console.log("error");
      });
  }
  updateProfile = () => {
    axios
      .put(
        api_url +
          "api/v1/dashboard/profile/"+this.state.profile.franchise_id,this.state.profile ,
        store.getState().user.requestHeader
      )
      .then((result) => {
        // console.log("from api client", result.data);
        this.toggleEdit();
        toast(
          <Notification type={"success"} message="successfully saved" />,
          options
        );
      })
      .catch((e) => {
        console.log("error");
      });
  }
  
  render() {
    return (
      <div>
        <Row>
          <Col className="pr-grid-col" xs={12} lg={8}>
            <Row className="gutter mb-4">
              <Col xs={12} md={12}>
                <Widget className="widget-p-md">
                  <div className="d-flex justify-content-between">
                    <div className="headline-3 d-flex align-items-center">Settings</div>
                    <Button onClick={this.toggleEdit} color="warning">{this.state.editFlag ? 'Cancel':'Edit'}</Button>
                    
                    {this.state.editFlag&&
                    <Button onClick={this.updateProfile} color="warning">Save</Button>
                    }
                    
                  </div>
                    <div key={uuidv4()} className={`mt-4 `}>
                       
                       <Table
                        >
                          
                          <tbody>
                            <tr>
                              
                              <td>
                              <h5>Delivery minimum charge:</h5>
                              </td>
                              <td>
                              {this.state.editFlag?
                              <Input 
                                name="miminum_delivery_charge" 
                                onChange={this.handleChange}
                                value={this.state.profile.miminum_delivery_charge} />
                              :<h5>₹{this.state.profile.miminum_delivery_charge}
                              </h5>}

                              </td>
                              
                            </tr>
                            <tr>
                            
                              <td>
                              <h5>Delivery charge per km:</h5>
                              </td>
                              <td>
                              {this.state.editFlag?<Input 
                                name="delivery_charge_per_km" 
                                onChange={this.handleChange}
                                value={this.state.profile.delivery_charge_per_km} />:
                              <h5>₹{this.state.profile.delivery_charge_per_km}</h5>}
                              </td>
                              
                            </tr>
                          
                          </tbody>
                        </Table>
                        
                    </div>
                </Widget>
              </Col>
            </Row>
          </Col>
          
        </Row>
      </div>
    )
  }
}

export default Dashboard;
