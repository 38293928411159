import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import {
  Button,
  Table,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  CustomInput,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import SimpleReactValidator from "simple-react-validator";
import env from "react-dotenv";

import store from "../../../store/index";
import Modal from "./Modal";
import Select from 'react-select'

var app_mode = env.MODE ? env.MODE: 'development';
var default_url = app_mode == 'production'? "https://api.mistershoppie.com/" : "https://api.keralashoppie.com/";
const api_url =env.API_URL?env.API_URL: default_url;
// const api_url = "http://localhost:3001/api/v1/";

const default_product = {
  product_name: {
    en: "",
    ml: "",
  },
  price: null,
  offer_price: null,
  product_image: null,
  id: null,
  is_available : true,
};

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const Products = () => {
  const [products, setProducts] = useState([]);
  const [productsDropdowns, setProductsDropdowns] = useState([]);

  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [form, setForm] = useState(default_product);
  const [isRefresh, setIsRefresh] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [loadFlag, setLoadFlag] = useState(false);
  const [imageFile, setImageFile] = useState({});
  const [productId, setProductId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isProductVarient, setIsProductVarient] = useState(false);

  const [productVarient, setProductVarient] = useState({});
  const [varients, setVarients] = useState([{phone:'',email:'',title:'',name:''}]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const storeId = useParams().storeId;

  const postForm = {
    store_id: storeId,
    page: 0,
    limit:500
  };

  useEffect(() => {

    fetchProducts(currentPage);
    fetchCategories();
    fetchProductDrops();
   
  }, []);

  const fetchProducts = (page) =>{
    setLoadFlag(true);
    axios.post(
      api_url + "api/v1/dashboard-products?limit=3",
      {
        store_id: storeId,
        page: page,
        limit:50
      },
      store.getState().user.requestHeader
    ).then(response =>{
      setLoadFlag(false);
      setProducts(response.data.data);

    }).catch(error => {
      console.log(error);
      setLoadFlag(false);

    })
  }
  const fetchProductDrops = () =>{
    setLoadFlag(true);
    axios.get(
      api_url + "api/v1/dashboard/product-dropdown",
      store.getState().user.requestHeader
    ).then(response =>{
      setLoadFlag(false);
      setProductsDropdowns(response.data.data);

    }).catch(error => {
      console.log(error);
      setLoadFlag(false);

    })
  }
  const fetchCategories = () =>{
    axios.get(
      api_url + "api/v1/dashboard-product-categories?store_id="+storeId,
      store.getState().user.requestHeader
    ).then(response =>{
      setCategories(response.data.data);

    }).catch(error => {
      console.log(error);
    })
  }
  
  const formChangeHandler = (event) => {
    switch (event.target.name) {
      case "product_name_en":
        setForm({
          ...form,
          product_name: {
            en: event.target.value,
            ml: form.product_name.ml,
          },
        });
        break;
      case "product_name_ml":
        setForm({
          ...form,
          product_name: {
            ml: event.target.value,
            en: form.product_name.en,
          },
        });
        break;
      case "product_price":
        setForm({ ...form, price: event.target.value });
        break;
      case "product_offer_price":
        setForm({ ...form, offer_price: event.target.value });
        break;
      case "product_image":
        // if (isUpdateMode && form.product_image) {
        //   return;
        // }
        setHasImage(true)
        setImageFile(event.target.files[0])
        // setForm({ ...form, ["product_image"]: event.target.files[0] });
        break;
      case "is_available":
        setForm({ ...form, ["is_available"]: event.target.checked });
        console.log(form.is_available);

        break;
      default:
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
    }
  };

  
  const submitHandler = async (event) => {
    
    event.preventDefault();
    if (simpleValidator.current.allValid()) {

      if(form.price < form.offer_price){
        alert('product offer price must be less than or equals to product price');
        return;
      }
      setLoadFlag(true);

      const formData = new FormData();
      formData.append("product_name_en", form.product_name.en);
      formData.append("product_name_ml", form.product_name.ml);
      formData.append("price", form.price);
      formData.append("offer_price", form.offer_price);
      formData.append("product_varient_id", form.product_varient_id);
      formData.append("is_available", form.is_available);
      formData.append("categories", form.categories);
      formData.append("quantity", 10);
      formData.append("store_id", storeId);
      formData.append("unit", form.unit);
      formData.append("type",form.type);
      formData.append("description",form.description);
      formData.append("added_by", 1);

      // alert(hasImage)
      // formData.append("added_by", store.getState().user.id);
      if(hasImage){
        formData.append("image", imageFile, imageFile.name);

      }
      console.log(isUpdateMode)

      if (isUpdateMode) {
          axios.put(
            api_url + `api/v1/dashboard/product-varient/${form.product_varient_id}`,
            formData,store.getState().user.requestHeader
          ).then( response => {
            modalHandler();
            fetchProducts();
            setLoadFlag(false);

          }).catch(e => {
            console.log(e)
            setLoadFlag(false);

          })
         
      }else if(isProductVarient){
        // if(!hasImage){
        //   alert('please add product image');
        //   return;
        // }
        formData.append("product_id", productId);

        axios.post(api_url + "api/v1/product-varient", formData,store.getState().user.requestHeader).then( res => {
          modalHandler();
          fetchProducts();
          setLoadFlag(false);

        }).catch(e => {
          console.log('product varient add error',e)
        })
      } else {
       
        axios.post(api_url + "api/v1/product", formData,store.getState().user.requestHeader).then( res => {
          modalHandler();
          fetchProducts();
          setLoadFlag(false);

        }).catch(e => {
          console.log('product add error',e)
        })
          
      }
    } else {
      alert('validation error please fill all the data');
      console.log(simpleValidator,'validator filed')
      simpleValidator.current.showMessages();
    }
  };

  const modalHandler = () => {
    setShow(!show);
    if (!isUpdateMode) {
      setForm(default_product);
    }
    if (isUpdateMode) {
      setIsUpdateMode(false);
    }
    setIsRefresh(!isRefresh);
    setIsProductVarient(false);
  };
  const deleteModalHandler = () => {
    setDeleteShow(!deleteShow);
    if (!isUpdateMode) {
      setForm(default_product);
    }
    if (isUpdateMode) {
      setIsUpdateMode(false);
    }
    setIsRefresh(!isRefresh);
    setIsProductVarient(false);
  };
  const addProductVarient = () => {
    setShow(!show);
    setIsProductVarient(true);
    setIsRefresh(!isRefresh);
  };
  

  const handleCellChange = (event, position) =>{
    var varients_state = varients;
    var varients_parsed =  JSON.parse(JSON.stringify(varients_state)) 
    varients_parsed[position][event.target.name] = event.target.value;
    setVarients(varients)

}
  const updateHandler = (product) => {
    axios.get(
      api_url + "api/v1/dashboard-products/"+product.product_varient_id,
      store.getState().user.requestHeader
    ).then(response =>{
      var product_varient = response.data.data;
      setProductVarient(response.data.data);
      setIsUpdateMode(!isUpdateMode);
      var product_varient = response.data.data;
      product.type = product_varient.type;
      product.unit = product_varient.unit;
      product.product_varient_id = product_varient.id;
      product.categories = product_varient.categories;
      product.description = product_varient.description;
      product.is_available = product_varient.is_available;

      setForm({ ...product });
      setShow(!show);

    }).catch(error => {
      console.log(error);
    })

    
   

  };
  const deleteHandler = (product) => {
    axios.get(
      api_url + "api/v1/dashboard-products/"+product.product_varient_id,
      store.getState().user.requestHeader
    ).then(response =>{
      var product_varient = response.data.data;
      product.type = product_varient.type;
      product.unit = product_varient.unit;
      product.product_varient_id = product_varient.id;
      product.categories = product_varient.categories;
      product.description = product_varient.description;
      product.is_available = product_varient.is_available;
      product.product_id = product_varient.product_id;

      setForm({ ...product });
      setDeleteShow(!deleteShow);

    }).catch(error => {
      console.log(error);
    })

      

  };
  const paginate = (e, type) => {
    e.preventDefault();
    var page = currentPage;

    if (type == "minus") {
      page = page == 1 ? 1 : page - 1;
    } else {
      page = page + 1;
    }
    setCurrentPage(page);
    fetchProducts(page);
  };
  const deleteProduct = (type) => {
    form.type = type;
    axios.post(
      api_url + "api/v1/dashboard-product-delete",form,
      store.getState().user.requestHeader
    ).then(response =>{
      alert(type+' has been successfully deleted!');
      setDeleteShow(!deleteShow);

    }).catch(error => {
      console.log(error);
    })

    

};

  const addRow = (e) => {
    e.preventDefault();
    let obj = {name:'',title:'',email:'',phone:''};
    // varients({ contacts: [...this.state.contacts, obj ] });
}
 const removeRow = (ind) => {
    var contacts_new = varients;
    var abc = contacts_new.splice(ind, 1);
    console.log(abc,ind,contacts_new)
    // this.setState({ contacts: contacts_new });
}
const getProductHandler = (e) => {
  setProductId(e.product_id)
}

  return (
    <>
      {!!products && (
        <div>
          <div className="d-flex mb-3 justify-content-end">
            <Button color="warning" onClick={modalHandler}>
              ADD PRODUCT
            </Button>
            <Button className="ml-3" color="warning" onClick={addProductVarient}>
              ADD PRODUCT VARIENT
            </Button>
          </div>
          <Modal
            show={deleteShow}
            modalHandler={deleteModalHandler}
            title={"Delete product"}
          >
            <Row>
                <Col md="12" sm="12">
                    <FormGroup>
                    <Button
                      color="warning"
                      style={{marginLeft:'2px'}}
                      onClick={() => deleteProduct('product')}
                    >
                      Delete all varient of this product
                    </Button>
                    <Button
                      color="warning"
                      style={{marginLeft:'2px'}}
                      onClick={() => deleteProduct('product_varient')}
                    >
                      Delete this varient only
                    </Button>
                    </FormGroup>
                  </Col>
              </Row>

          </Modal>
          <Modal
            show={show}
            modalHandler={modalHandler}
            title={isUpdateMode ? "Update Product" : isProductVarient ? "Add new varient to product":"Add Product"}
            submitHandler={submitHandler}
          >
                {loadFlag && (
                  <div className="bg-widget d-flex justify-content-center">
                    <Spinner type="grow" color="dark" />
                    <Spinner type="grow" color="warning" />
                    <Spinner type="grow" color="dark" />
                  </div>
                ) }
            <Form inline className="mx-5">
              <Row>

                <Row>
                {isProductVarient && ( 
                <Col md="12" sm="12">
                    <FormGroup>
                      <Label for="product name English">
                        Products
                      </Label>
                      <Col>
                        <Select 
                        onChange={getProductHandler}
                        options={productsDropdowns} 
                        getOptionLabel={(option) => `${option.product_name.en}`}
                        getOptionValue={(option) => `${option['product_id']}`}
                        placeholder="select product to add varient"/>
                      
                      </Col>
                    </FormGroup>
                    
                  </Col>)}
                
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label for="product name English">
                        Product Name (English)
                      </Label>
                      <Input
                        name="product_name_en"
                        onChange={formChangeHandler}
                        value={form.product_name.en}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            "product_name_en"
                          )
                        }
                      />
                      {simpleValidator.current.message(
                        "product_name_en",
                        form.product_name.en,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="product name malayalam">
                        Product Name (മലയാളം)
                      </Label>
                      <Input
                        name="product_name_ml"
                        onChange={formChangeHandler}
                        value={form.product_name.ml}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            "product_name_ml"
                          )
                        }
                      />
                      {simpleValidator.current.message(
                        "product_name_ml",
                        form.product_name.ml,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Row>
                            <Label for="product price">Product Price</Label>
                          </Row>
                          <Row>
                            <Input
                              name="product_price"
                              type="number"
                              onChange={formChangeHandler}
                              value={form.price}
                              onBlur={() =>
                                simpleValidator.current.showMessageFor(
                                  "product_price"
                                )
                              }
                            />
                            {simpleValidator.current.message(
                              "product_price",
                              form.price,
                              "required|numeric",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Row>
                            <Label for="product offer price">
                              Product Offer Price
                            </Label>
                          </Row>
                          <Row>
                            <Input
                              name="product_offer_price"
                              type="number"
                              onChange={formChangeHandler}
                              value={form.offer_price}
                              onBlur={() =>
                                simpleValidator.current.showMessageFor(
                                  "product_offer_price"
                                )
                              }
                            />
                            {simpleValidator.current.message(
                              "product_offer_price",
                              form.offer_price,
                              "required|numeric",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  
                </Row>
                <Row>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label for="product name English">
                        Product varient type(eg:large, Xl, 1/2 Kg)
                      </Label>
                      <Input
                        name="type"
                        onChange={formChangeHandler}
                        value={form.type}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            "type"
                          )
                        }
                      />
                      {simpleValidator.current.message(
                        "type",
                        form.type,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="product name malayalam">
                        Description
                      </Label>
                      <Input
                      type="textarea"
                        name="description"
                        onChange={formChangeHandler}
                        value={form.description}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            "description"
                          )
                        }
                      />
                      {simpleValidator.current.message(
                        "description",
                        form.description,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Row>
                        <Col>
                          <Row>
                            <Label for="product unit">unit</Label>
                          </Row>
                          <Row>
                            <Input
                              name="unit"
                              onChange={formChangeHandler}
                              value={form.unit}
                              onBlur={() =>
                                simpleValidator.current.showMessageFor(
                                  "unit"
                                )
                              }
                            />
                            {simpleValidator.current.message(
                              "unit",
                              form.unit,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Row>
                            <Label for="product offer price">
                              Product Category
                            </Label>
                          </Row>
                          <Row>
                              <Input type="select" className="form-control" name="categories"
                              onChange={formChangeHandler}
                              value={form.categories}  
                              onBlur={() =>
                                simpleValidator.current.showMessageFor(
                                  "categories"
                                )
                              }
                              >
                                <option >Select category</option>

                                {categories.map((cat,key) => (
                                <option value={cat.id}>{cat.title.en}</option>

                                ))}
                              </Input>
                              {simpleValidator.current.message(
                              "categories",
                              form.categories,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  
                </Row>
                {/* <Col className="mt-3" md="6">
                <FormGroup>
                        <Label for="Image">Available </Label>
                        <Col>
                          
                          <CustomInput 
                            onChange={formChangeHandler}
                            value={form.is_available}
                            type="switch" id={'switch49'} name="is_available"  />
                          
                        </Col>
                      </FormGroup>
                
                </Col> */}
                <Row>
                <Col md="6">
                {/* !isUpdateMode && */}
                {form.product_image&&
                <img
                  style={{width: "45px",
                    height: "45px",
                    border: "2px solid #43bc13",
                    padding: "1px"}}
                  src={form.product_image}
                  alt="User"
                />}
                { !isProductVarient && (
                 

                      <FormGroup>
                        <Label for="Image">Image {isUpdateMode}</Label>
                          <Input
                            onChange={formChangeHandler}
                            id="formFile"
                            name="product_image"
                            type="file"
                            
                          />
                          
                      </FormGroup>
                )}
                </Col>

                <Col md="6" >
                    
                    <Row>
                      <Col md="12">
                      <Label for="Image"> product in stock or out stock</Label>

                        <CustomInput 
                        type="switch" 
                        id="exampleCustomSwitch" 
                        name="is_available" 
                        label="Product available" 
                        bsSize="lg"
                        checked={form.is_available? true:false}
                        onChange={formChangeHandler} />
                          
                      </Col>
                    </Row>
                          
                  
              </Col>
                
                </Row>
                
                
              </Row>
            </Form>
          </Modal>
          {loadFlag && (
                    <div className="bg-widget d-flex justify-content-center">
                      <Spinner type="grow" color="dark" />
                      <Spinner type="grow" color="warning" />
                      <Spinner type="grow" color="dark" />
                    </div>
                  ) }
          <Table bordered responsive striped size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Price(₹)</th>
                <th>Offer Price(₹)</th>
                
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {products.length > 0 ? products.map((product, index) => (
                <tr key={index}>
                  <th scope="row" className="text-align-center">
                    {parseInt((currentPage-1) * 50) + parseInt(index + 1)}
                  </th>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        className="store__product--image"
                        src={product.product_image}
                        alt="not found"
                      />
                      <h4 className="ml-3">{product.product_name.en} - {product.type}</h4>
                    </div>
                  </td>
                  <td>{product.price}</td>
                  <td>{product.offer_price}</td>
                  
                  <td>
                    <Button
                      color="warning"
                      onClick={updateHandler.bind(this, product)}
                    >
                      UPDATE
                    </Button>
                    <Button
                      color="warning"
                      onClick={deleteHandler.bind(this, product)}
                      style={{marginLeft:'2px'}}
                    >
                      DELETE
                    </Button>
                  </td>
                </tr>
              )):(
                <tr>
                  <td colSpan="4">
                    <h3>No products added yet or end of the product list</h3>  
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
            <Pagination className="pagination-with-border">
              <PaginationItem>
                <PaginationLink
                  previous
                  href="#"
                  onClick={(e) => paginate(e, "minus")}
                />
              </PaginationItem>

              <PaginationItem>
                <PaginationLink
                  next
                  href="#"
                  onClick={(e) => paginate(e, "plus")}
                />
              </PaginationItem>
            </Pagination>
            {loadFlag && (
                    <div className="bg-widget d-flex justify-content-center">
                      <Spinner type="grow" color="dark" />
                      <Spinner type="grow" color="warning" />
                      <Spinner type="grow" color="dark" />
                    </div>
                  ) }
        </div>
      )}
    </>
  );
};

export default Products;
